import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LogOut = () => {
    const navigate = useNavigate();

    useEffect(() => {     
            localStorage.removeItem('Token');

            navigate('/');
        
    }, []);
    return (
        <>
        </>
    )
}
export default LogOut;
